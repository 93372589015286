<template>
	<div>
		<b-card>
			<b-row>
				<b-col cols="6">
					<label>Project</label>

					<v-select
						v-model="projectId"
						class="flex-fill"
						clearable
						filterable
						searchable
						:options="projects"
						:reduce="(o) => o.id"
						placeholder="Select a donor"
					/>
				</b-col>

				<b-col class="d-flex justify-content-end align-items-end">
					<b-button variant="primary" :disabled="!disable" @click="fetchSubmissions">Apply</b-button>
				</b-col>
			</b-row>
		</b-card>

		<!--
		excel-file-name="excel-file"
		paginate
		table-top
		-->
		<base-table
			:headers="[
				{ key: 'project_id' },
				{ key: 'weakness', label: 'weakness / Gaps' },
				{ key: 'corrective_actions' },
				// { key: 'created_at', label: 'Date' },

				{ key: 'user.name', label: 'user' },
			]"
			:items="submissions"
			no-search
		>
			<template #cell(created_at)="{ item }">
				<span>{{ new Date(item.created_at).toLocaleDateString() }}</span>
			</template>
			<template #cell(weakness)="{ item }">
				<div>
					<p v-for="(weaknessItem, index) in item.weakness" :key="index">{{ index + 1 }} : {{ weaknessItem }}</p>
				</div>
			</template>
			<template #cell(corrective_actions)="{ item }">
				<div>
					<p v-for="(correctiveItem, index) in item.corrective_actions" :key="index">{{ index + 1 }} : {{ correctiveItem }}</p>
				</div>
			</template>
		</base-table>
	</div>
</template>

<script>
import vSelect from 'vue-select';
import { BButton, BCard, BFormDatepicker, BRow, BCol } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import api from '@/utils/api';
import { mapGetters } from 'vuex';
import store from '@/store';

export default {
	name: 'SafeguardTableWithFilter',

	components: {
		vSelect,
		BaseTable,
		BButton,
		BCard,
		// BFormDatepicker,
		BRow,
		BCol,
	},

	data: () => ({
		projects: [],
		submissions: [],
		projectId: '',
	}),

	computed: {
		disable() {
			return !!this.projectId;
		},
		...mapGetters('attributes', ['getCities', 'getDonors', 'getSectors']),
	},

	async created() {
		const data = await store.dispatch('projects/getProjectShortList');

		this.projects = data.map((p) => ({
			label: `${p.SP_TITLE} - ${p.SP_PROJID}`,
			id: p.SP_PROJID,
		}));
	},

	methods: {
		async fetchSubmissions() {
			const params = new URLSearchParams({
				project_id: this.projectId || '',
			});

			const { data } = await api.call({
				path: `reports/main-weakness-corrective-actions?${params}`,
				method: 'GET',
			});

			this.submissions = data;
		},
	},
};
</script>

<style lang="scss" scoped>
.form-filter {
	gap: 1rem;
}
</style>
