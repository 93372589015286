var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v("Project")]),_c('v-select',{staticClass:"flex-fill",attrs:{"clearable":"","filterable":"","searchable":"","options":_vm.projects,"reduce":function (o) { return o.id; },"placeholder":"Select a donor"},model:{value:(_vm.projectId),callback:function ($$v) {_vm.projectId=$$v},expression:"projectId"}})],1),_c('b-col',{staticClass:"d-flex justify-content-end align-items-end"},[_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.disable},on:{"click":_vm.fetchSubmissions}},[_vm._v("Apply")])],1)],1)],1),_c('base-table',{attrs:{"headers":[
			{ key: 'project_id' },
			{ key: 'weakness', label: 'weakness / Gaps' },
			{ key: 'corrective_actions' },
			// { key: 'created_at', label: 'Date' },

			{ key: 'user.name', label: 'user' } ],"items":_vm.submissions,"no-search":""},scopedSlots:_vm._u([{key:"cell(created_at)",fn:function(ref){
		var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.created_at).toLocaleDateString()))])]}},{key:"cell(weakness)",fn:function(ref){
		var item = ref.item;
return [_c('div',_vm._l((item.weakness),function(weaknessItem,index){return _c('p',{key:index},[_vm._v(_vm._s(index + 1)+" : "+_vm._s(weaknessItem))])}),0)]}},{key:"cell(corrective_actions)",fn:function(ref){
		var item = ref.item;
return [_c('div',_vm._l((item.corrective_actions),function(correctiveItem,index){return _c('p',{key:index},[_vm._v(_vm._s(index + 1)+" : "+_vm._s(correctiveItem))])}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }